import React from 'react';
import { mousewheel } from '../../utils/mousewheel';
import './VerticalSlides.scss';

export default class VerticalSlides extends React.Component {
  touched = false;
  children = [];
  collectScrolls = 0;
  minScrollToSlide = 200;
  count = 0;

  constructor(props) {
    super(props);
    
    this.count = React.Children.count(props.children);
    this.state = {
      selected: 0,
      isFirst: true,
      isLast: !(this.count > 1)
    }

    this.cloneChildren();
  }

  componentDidMount() {
    setTimeout(() => this.children[this.state.selected].ref.current.showContent(), 1000)
  }

  getStatusClass(idx) {
    let className = 'vertical-slide';
    if (idx === this.state.selected) {
      className += ' selected'
    } else if (idx < this.state.selected) {
      className += ' backward'
    } else {
      className += ' forward'
    }

    if (this.state.prev === idx) {
      className += ' previous';
    }
    return className;
  }

  handleMove(event) {
    if (this.props.disableWheel) {
      return;
    }
    const ev = mousewheel(event);
    if (!ev) {
      return;
    }
    // const content = this.children[this.state.selected].ref.current.content.current;
    // const container = this.children[this.state.selected].ref.current.container.current;
    const scrollSize = Math.ceil(Math.abs(ev.deltaY) * ev.deltaFactor);
    // const browserScrollRate = (window.isFirefox) ? 2 : 1;
    // const OSScrollRate = (window.isWindows) ? browserScrollRate * 2 : browserScrollRate;
    // const wheelDelta = (event.nativeEvent.wheelDelta) ? event.nativeEvent.wheelDelta : ev.deltaY * ev.deltaFactor;
    // const energy = wheelDelta * browserScrollRate * OSScrollRate;
    const scrollDirection = (ev.deltaY >= 0) ? "up" : "down";
    // const curSecScrollTop = content.scrollTop;
    // const currentSectionHeight = container.clientHeight || container.offsetHeight;
    // const minScrollToSlide = (window.isFirefox && window.isWindows) ? 200 : this.minScrollToSlide;
   
    if (!scrollSize || this.state.sliding) {
      return;
    }

    // console.log(scrollSize, energy, scrollDirection, curSecScrollTop, currentSectionHeight, minScrollToSlide);
    if (scrollDirection === 'up') {
      this.collectScrolls += scrollSize;
    } else {
      this.collectScrolls -= scrollSize;
    }

    setTimeout(() => {
      this.collectScrolls = 0;
    }, 200);

    if (Math.abs(this.collectScrolls) >= this.minScrollToSlide) {
      if (scrollDirection === 'down' && !this.state.isLast) {
        this.navigateToSlide(this.state.selected + 1);
      } else if (scrollDirection === 'up' && !this.state.isFirst) {
        this.navigateToSlide(this.state.selected - 1);
      }
    }
  }

  enableTouchMove() {
    this.touched = true;
  }
  disableTouchMove() {
    this.touched = false;
  }

  touchMove(ev) {
    if (this.touched) {
      this.handleMove(ev);
    }
  }

  cloneChildren() {
    this.children = React.Children.toArray(this.props.children).map((el, idx) => React.cloneElement(el, {
      ref: React.createRef(),
      key: idx
    }));
  }

  navigateToSlide(slide) {
    if (slide < 0 || slide >= this.children.length) {
      return;
    }
    if (this.props.onChangeSlide) {
      this.props.onChangeSlide(slide);
    }
    if (this.state.sliding) {
      return;
    }
    for (const child of this.children) {
      child.ref.current.hideContent();
    }
    clearInterval(this.timeout);
    this.setState({
      selected: slide,
      prev: this.state.selected,
      sliding: true,
      isFirst: slide === 0,
      isLast: slide === this.count - 1
    });
    this.timeout = setTimeout(() => {
      this.setState({ prev: null, sliding: false })
    }, 1000);

    setTimeout(() => this.children[this.state.selected].ref.current.showContent(), 1000)
  }

  next() {
    this.navigateToSlide(this.state.selected + 1);
  }
  prev() {
    this.navigateToSlide(this.state.selected - 1);
  }

  render() {
    const slides = this.children.map((el, idx) => {
      return (<div className={this.getStatusClass(idx)} key={idx} data-index={idx}>{el}</div>);
    });

    return (
      <div className="vertical-slides" 
        onWheel={ev => this.handleMove(ev)}
        onTouchStart={() => this.enableTouchMove()}
        onTouchEnd={() => this.disableTouchMove()}
        onTouchCancel={() => this.disableTouchMove()}
        onTouchMove={ev => this.touchMove(ev)}>
        <div className={`vertical-slider`}>
          { slides }
        </div>

        <nav>
          <div className="vertical-navigation">
            <ul>
              {slides.map((_, idx) => (
                <li 
                  className={idx === this.state.selected ? 'selected' : ''}
                  key={idx} 
                  onClick={() => this.navigateToSlide(idx)}>{idx}</li>)
              )}
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}