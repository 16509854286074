import Lottie from "lottie-react";
import checkErrorLottie from '../../lotties/warn.json';

export default function ErrorModal({ children }) {
  return (
    <div className="modal-content modal-error">
      <Lottie className="modal-icon" animationData={checkErrorLottie} loop={false}></Lottie>

      <div>
        {children}
      </div>
    </div>
  );
}