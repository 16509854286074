import anime from 'animejs';

export function initShapeLoop(shapes, el, pos, loop = false) {
  pos = pos || 0;
  anime.remove(el);
  return anime({
    targets: el,
    easing: 'linear',
    d: [{ value: shapes[pos].pathAlt, duration: 1500 }, { value: shapes[pos].path }],
    loop,
    fill: {
      value: shapes[pos].fill.color,
      duration: shapes[pos].fill.duration,
      easing: shapes[pos].fill.easing
    },
    direction: 'alternate'
  })
};

export function setShapeLoop(shapes, el, pos) {
  anime.remove(el);
  anime({
    targets: el,
    duration: shapes[pos].animation.path.duration,
    easing: shapes[pos].animation.path.easing,
    elasticity: shapes[pos].animation.path.elasticity || 0,
    d: shapes[pos].path,
    fill: {
      value: shapes[pos].fill.color,
      duration: shapes[pos].fill.duration,
      easing: shapes[pos].fill.easing
    },
    complete: () => {
      initShapeLoop(shapes, el, pos);
    }
  })
}

export function setMorphing(shapes, wrap, svg, path, pos) {
  wrap.style.backgroundColor = shapes[pos].bgColor;
  // const classNames = ['primary-mask', 'secondary-mask', 'tertiary-mask', 'quaternary-mask'];
  // document.body.classList.remove(...classNames);
  // document.body.classList.add(classNames[pos])
  anime.remove(svg);
  anime({
    targets: svg,
    duration: shapes[pos].animation.svg.duration,
    easing: shapes[pos].animation.svg.easing,
    scaleX: shapes[pos].scaleX,
    scaleY: shapes[pos].scaleY,
    translateX: shapes[pos].tx+'px',
    translateY: shapes[pos].ty+'px',
    rotate: shapes[pos].rotate+'deg'
  });
  setShapeLoop(shapes, path, pos);
}

