import HorizontalSlide from '../../components/HorizontalSlides/HorizontalSlide';
import HorizontalSlides from '../../components/HorizontalSlides/HorizontalSlides';
import VerticalSlides from '../../components/VerticalSlides/VerticalSlides';
import VerticalSlide from '../../components/VerticalSlides/VerticalSlide';
import './Home.scss';
import Configurator from '../../components/Configurator/Configurator';
import MainTitle from '../../components/MainTitle/MainTitle';
import HugeButton from '../../components/Button/HugeButton';
import Lottie from 'lottie-react';
import contactLottieData from '../../lotties/5504-shake-phone.json';
import servicesLottieData from '../../lotties/69395-man-working-on-system.json';
import cleanLottieData from '../../lotties/cleanning.json';

// import morphBackgroundOne from '../../images/morph-background-1.svg';
// import morphBackgroundTwo from '../../images/morph-background-2.svg';
// import morphBackgroundThree from '../../images/morph-background-3.svg';
import { useRef, useEffect } from 'react';
import { setMorphing } from '../../utils/animations';
import { shapes } from './animations';
import Header from '../../components/Header/Header';

let cStep = 0;

function HomeSlide() {
  const onChangeStep = step => {
    cStep = step;
   
    if (step === 5) {
      Header.header.hide();
    } else {
      Header.header.show();
    }
  } 

  return (
  <>
    <Configurator onChangeStep={step => onChangeStep(step)}/>
  </>
  )
}

function ServiceSlide() {
  return (<>
    <div className="">
      <MainTitle>Leistungen</MainTitle>
    </div>

    <div className="lottie not-animated"><Lottie animationData={servicesLottieData} loop={false} /></div>

    <div className="d-flex justify-content-center open-button">
      <HugeButton to="/unsere_leistungen" color="primary">Öffnen</HugeButton>
    </div>

    <p className="description">UNSER LEISTUNGSSPEKTRUM</p>
  </>);
}

function GutterRepairSlide() {
  return (<>
    <div className="">
      <MainTitle className="text-break">Dachrinnenreparatur</MainTitle>
    </div>

    <div className="lottie not-animated"><Lottie animationData={cleanLottieData} loop={false} /></div>

    <div className="d-flex justify-content-center open-button">
      <HugeButton to="/reperatur_and_reinigung" color="primary">Öffnen</HugeButton>
    </div>

    <p className="description">Für Privat, Gewerbe und Industrie</p>
  </>);
}

function ContactSlide() {
  return (<>
    <div className="">
      <MainTitle>Kontakt</MainTitle>
    </div>
    <div className="lottie not-animated"><Lottie animationData={contactLottieData} loop={false} /></div>
    
    <div className="d-flex justify-content-center open-button">
      <HugeButton to="/kontakt" color="primary">Öffnen</HugeButton>
    </div>

    <p className="description">Schreiben Sie uns eine Nachricht.</p>
  </>);
}

export default function HomePage() {
  const wrapRef = useRef();
  const svgRef = useRef();
  const pathRef = useRef();

  useEffect(() => {
    setMorphing(shapes, wrapRef.current, svgRef.current, pathRef.current, 0);
    return () => {
      const classNames = ['primary-mask', 'secondary-mask', 'tertiary-mask', 'quaternary-mask'];
      document.body.classList.remove(...classNames);
    }
  }, []);
  
  const onChangeSlide = slide => {
    setMorphing(shapes, wrapRef.current, svgRef.current, pathRef.current, slide);
    if (slide === 0 && cStep < 5) {
      Header.header.show();
    }else {
      Header.header.hide();
    }
  }

  return (
    <div className="home-page">
      <div ref={wrapRef} className="morph-wrap">
        <svg ref={svgRef} className="morph" width="1400" height="770" viewBox="0 0 1400 770">
          <path ref={pathRef} d="M -25.298 124.697 C -78.098 216.65 212.6 473.468 288.8 544.357 C 372.2 621.983 511.2 505.758 620.3 525.539 C 750.6 549.169 860.3 725.162 987.3 686.136 C 1089 654.915 1168 523.828 1173 411.026 C 1178 287.531 841.173 393.965 740.273 331.523 C 597.273 242.104 637.564 160.912 476.864 203.948 C 389.464 227.364 34.402 20.555 -25.298 124.697 Z" fill="rgba(52, 37, 96, 0.1)"></path>
        </svg>
      </div>
      <HorizontalSlides onChangeSlide={slide => onChangeSlide(slide)}>
        <HorizontalSlide title="HOME">
          <VerticalSlides disableWheel={true}>
            <VerticalSlide animation="fromLeft">
              <HomeSlide />
            </VerticalSlide>
          </VerticalSlides>
        </HorizontalSlide>

        <HorizontalSlide title="Unsere Leistungen">
          {/* <img src={morphBackgroundOne} alt="morph background one" className="slide-background" /> */}
          <VerticalSlides disableWheel={true}>
            <VerticalSlide animation="fromLeft">
              <ServiceSlide />
            </VerticalSlide>
          </VerticalSlides>
        </HorizontalSlide>

        <HorizontalSlide title="Dachrinnenreperatur">
          {/* <img src={morphBackgroundTwo} alt="morph background one" className="slide-background" /> */}
          <VerticalSlides disableWheel={true}>
            <VerticalSlide animation="fromLeft">
              <GutterRepairSlide />
            </VerticalSlide>
          </VerticalSlides>
        </HorizontalSlide>

        <HorizontalSlide title="Kontakt">
          {/* <img src={morphBackgroundThree} alt="morph background one" className="slide-background" /> */}
          <VerticalSlides disableWheel={true}>
            <VerticalSlide animation="fromLeft">
              <ContactSlide />
            </VerticalSlide>
          </VerticalSlides>
        </HorizontalSlide>
      </HorizontalSlides>
    </div>
  )
}