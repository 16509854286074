import { useEffect, useRef } from 'react';
import Button from '../../components/Button/Button';
import VerticalSlide from '../../components/VerticalSlides/VerticalSlide';
import VerticalSlides from '../../components/VerticalSlides/VerticalSlides';
import { setMorphing } from '../../utils/animations';
import { shapes } from './animations';
import './Repair.scss';

export default function RepairPage() {

  const wrapRef = useRef();
  const svgRef = useRef();
  const pathRef = useRef();
  const slidesRef = useRef();

  useEffect(() => {
    setMorphing(shapes, wrapRef.current, svgRef.current, pathRef.current, 0);
    return () => {
      const classNames = ['primary-mask', 'secondary-mask', 'tertiary-mask', 'quaternary-mask'];
      document.body.classList.remove(...classNames);
    }
  }, []);

  const onChangeSlide = (slide) => {
    setMorphing(shapes, wrapRef.current, svgRef.current, pathRef.current, slide);
  }

  return (
    <div className="text-white">
      <div ref={wrapRef} className="morph-wrap">
				<svg ref={svgRef} className="morph" width="1400" height="770" viewBox="0 0 1400 770">
					<path ref={pathRef} d="M 262.9,252.2 C 210.1,338.2 212.6,487.6 288.8,553.9 372.2,626.5 511.2,517.8 620.3,536.3 750.6,558.4 860.3,723 987.3,686.5 1089,657.3 1168,534.7 1173,429.2 1178,313.7 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z"></path>
				</svg>
			</div>
      <VerticalSlides ref={slidesRef} onChangeSlide={slide => onChangeSlide(slide)}>
        <VerticalSlide animation="fromLeft" >
          <h2 className="section-title">
            Dachrinnenreparatur
            <span className="d-block fs-2 fw-bold"> für Privat, Gewerbe und Industrie</span>  
          </h2>
          <p className="section-content">
            Ihre Dachrinnen sind undicht? Nachts können Sie wegen nerviger 
            Tropfgeräusche auf Ihre Terrassenüberdachung nicht schlafen? Ihre 
            Kunden werden auf dem Weg zur Ladentür von einem feinen Wasserstrahl 
            „geduscht“? Ursache hierfür sind meist unfachmännische oder auch 
            altersschwache Lötnähte. Sprechen Sie uns einfach an! Wir bieten Ihnen 
            hochwertige und kostengünstige Möglichkeiten zur Dachrinnenabdichtung 
            an.
          </p>
          <Button className="next-button" onClick={() => slidesRef.current.next()}>Weitere Informationen</Button>
        </VerticalSlide>
        <VerticalSlide animation="fromLeft">
          <h2 className="section-title">
            Wir beraten Sie gerne
            <span className="d-block fw-bold fs-2">über individuelle, rhythmische Pflege Ihrer Dachflächen und Ablaufrinnen</span>  
          </h2>
          <ul>
            <li>Montage von Laubabfangeinsätzen, Stutzen und Rinnen</li>
            <li>Rinnenaustausch, Reparatur und Neuanlage zu fairen Preisen</li>
            <li>Bei 3 Jährigem Wartungsvertrag bis zu 10% Dauer-Rabatt</li>
          </ul>
          <Button className="next-button" onClick={() => slidesRef.current.next()}>Weitere Informationen</Button>
        </VerticalSlide>
        <VerticalSlide animation="fromLeft">
          <h2 className="section-title">
            Das beinhaltet
            <span className="d-block fw-bold fs-2">eine Dachrinnenwartung:</span>
          </h2>
          <ul>
            <li>Reinigung und Überprüfung der Dachrinnen und Abflussstutzen/ Sammelkästen</li>
            <li>Entfernen von Pflanzeneinwuchs</li>
            <li>Entfernen von funktionsbbeeinträchtigenden Schmutzablagerungen</li>
            <li>Überprüfung der mechanischen Festigkeit der Dachrinne und Fallrohre etc.</li>
            <li>Überprüfung der An- und Abschlüsse</li>
          </ul>
          <Button className="next-button" onClick={() => slidesRef.current.next()}>Weitere Informationen</Button>
        </VerticalSlide>
        <VerticalSlide animation="fromLeft">
          <h2 className="section-title">
            Sowie,
            <span className="d-block fw-bold fs-2">nach Absprache falls nötig:</span>
          </h2>
          <ul>
            <li>Sofort Reparatur oder Auswechslung schadhafter Stellen, Nahtabdichtung etc.</li>
            <li>Schriftliches Protokoll mit weiteren Maßnahmen zu Pflege und Erhalt</li>
            <li>Angebot über die Maßnahmen</li>
          </ul>
          <Button to="/kontakt" className="next-button" >JETZT TERMIN VEREINBAREN</Button>
        </VerticalSlide>
      </VerticalSlides>
    </div>
  )
}