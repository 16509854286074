import { useRef, useEffect, useState } from 'react'
import ContactForm from '../../components/ContactForm/ContactForm';
import VerticalSlide from '../../components/VerticalSlides/VerticalSlide';
import VerticalSlides from '../../components/VerticalSlides/VerticalSlides';
import { setMorphing } from '../../utils/animations';
import { shapes } from './animations';
import './Contact.scss';
import { contactUrl } from '../../utils/contansts';
import SuccessModal from '../../components/Modal/SuccessModal';
import Modal from '../../components/Modal/Modal';
import { getBody } from '../../utils/functions';
import ErrorModal from '../../components/Modal/ErrorModal';

function ContactInfo() {
  return (
    <div className="h-100 d-flex flex-column justify-content-center">
      <h2 className="section-title">
        Schreiben Sie
        <span className="d-block fs-1 fw-biold"> uns eine Nachricht.</span>
      </h2>
      <p className="section-description">Nutzen Sie einfach unser Kontaktformular und senden uns Ihr Anliegen direkt zu. Wir werden uns schnellstmöglich darum kümmern.</p>
      <div className="mt-3">
        <p><a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Insterburger+Str.+5,+28207+Bremen,+Alemania/@53.0719066,8.8659902,17z/data=!3m1!4b1!4m5!3m4!1s0x47b12790164d905d:0x93a00d75e7f5eeaf!8m2!3d53.0719066!4d8.8681789">Insterburger Str. 5 28207 Bremen</a></p>
        <p><a href="tel:+4904216485770">+49 (0) 421 648577-0</a></p>
        <p><a href="mailto:regenrinnenreinigung@bluebirdfacility.de">regenrinnenreinigung@bluebirdfacility.de</a></p>
      </div>
    </div>
  )
}


export default function ContactPage() {
  const wrapRef = useRef();
  const svgRef = useRef();
  const pathRef = useRef();
  const slidesRef = useRef();
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showError, setShowError ] = useState(false);

  useEffect(() => {
    setMorphing(shapes, wrapRef.current, svgRef.current, pathRef.current, 0);
    return () => {
      const classNames = ['primary-mask', 'secondary-mask', 'tertiary-mask', 'quaternary-mask'];
      document.body.classList.remove(...classNames);
    }
  }, []);

  const onChangeSlide = (slide) => {
    setMorphing(shapes, wrapRef.current, svgRef.current, pathRef.current, slide);
  }

  const onSubmit = (form) => {
    const data = {
      from: 'Kontakt Formular',
      to: 'regenrinnenreinigung@bluebirdfacility.de',
      subject: 'Kontakt',
      body: `     
      <strong>Name: </strong> ${form.name} <br />
      <strong>Email: </strong> ${form.email} <br />
      <strong>Phone: </strong> ${form.phone} <br />
      <strong>Zip code: </strong> ${form.zip} <br />
      
      <strong> Content </strong> <br />
      ${form.content}`
    }

    fetch(contactUrl, {
      method: 'POST',
      body: getBody(data),
      headers: new Headers({
        'Content-Type': "application/x-www-form-urlencoded"
      })
    })
    .then(res => {
      setShowSuccess(true)
    })
    .catch(()=> {
      setShowError(true)
    })
  }

  return (
  <div className="text-white contact-page">
    <div ref={wrapRef} className="morph-wrap">
      <svg ref={svgRef} className="morph" width="1400" height="770" viewBox="0 0 1400 770">
        <path ref={pathRef} d="M 262.9,252.2 C 210.1,338.2 212.6,487.6 288.8,553.9 372.2,626.5 511.2,517.8 620.3,536.3 750.6,558.4 860.3,723 987.3,686.5 1089,657.3 1168,534.7 1173,429.2 1178,313.7 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z"/>
      </svg>
    </div>
    <VerticalSlides ref={slidesRef} onChangeSlide={slide => onChangeSlide(slide)} >
      <VerticalSlide>
        <div className="row">
          {/* <div className="col-12">
            <h2 className="section-title">
              Schreiben Sie
              <span className="d-block fs-1 fw-biold"> uns eine Nachricht.</span>
            </h2>
          </div> */}
          <div className="col-12 col-md-6">
            <ContactInfo />
          </div>
          <div className="col-12 col-md-6">
            <ContactForm showContentField={true} showTitle={false} onSubmit={form => onSubmit(form)}/>
          </div>
        </div>
      </VerticalSlide>
    </VerticalSlides>

    {showSuccess && <Modal onClose={() => setShowSuccess(false)}>
      <SuccessModal>
        <div className="fs-4 text-success mb-2">Danke, dass Sie uns kontaktiert haben</div>
        <div>Sie erhalten in Kürze eine Antwort</div>
      </SuccessModal>
    </Modal>}
    {showError && <Modal onClose={() => setShowError(false)}>
      <ErrorModal>
        <div className="fs-4 text-danger" style={{marginTop: '-3rem'}}>Ein Fehler ist aufgetreten</div>
      </ErrorModal>
    </Modal>}
  </div>);
}