import ButtonCreator from "./ButtonCreator";

export default function HugeButton(props) {
  let className = 'btn-huge';
  if (props.className) {
    className += ` ${props.className || ''}`;
  }
  if (props.color) {
    className += ` ${props.color || ''}`;
  }
  return (
    <ButtonCreator {...props} className={className}>
      <span className="btn-content-wrapper">
        <span className="btn-content">{props.children}</span>
      </span>
    </ButtonCreator>
  );
}