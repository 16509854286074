import React, { Children, useState } from 'react';
import { mousewheel } from '../../utils/mousewheel';
import './HorizontalSlides.scss';

export default function HorizontalSlides({ children, disableWheel, onChangeSlide }) {
  const [ initial, setInitial ] = useState(true);
  const [ index, setIndex ] = useState(0);
  const [ show, setShow ] = useState(true);
  const [ sliding, setSliding ] = useState(false);
  const childrenWithRef = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
        ref: React.createRef()
    });
  });
  const childrenArray = Children.toArray(childrenWithRef);
  const slidesCount = childrenArray.length;
  const minScrollToSlide = 200;
  // let rows = Math.floor(slidesCount / 4);
  // if (slidesCount % 4 !== 0) {
  //   rows++;
  // }
  // const currentRow = Math.floor(index / 4);
  // const indicatorTop = currentRow / rows * 100;
  const indicatorLeft = index * 63;

  const styles = {};

  const currentTabStyles = {};

  styles.transform = `translateX(calc(${-index} * 100%))`;
  let collectScrolls = 0;

  currentTabStyles.left = `${indicatorLeft}px`;
  // currentTabStyles.top = `calc(${indicatorTop}% + 6px)`;

  if (index !== 0 && initial) {
    setInitial(false)
  }

  const onStartClick = () => {
    if (!initial) {
      return;
    }
    setInitial(false)
    setIndex(1);
  }

  const navigateToSlide = (slide) =>  {
    setSliding(true);
    setIndex(slide);
    onChangeSlide && onChangeSlide(slide)
    setShow(false);
    setTimeout(() => {
      setShow(true);
      setSliding(false)
    }, 1000);
  }

  const onWheel = (event) => {
    if (disableWheel) {
      return;
    }
    const ev = mousewheel(event);
    const scrollSize = Math.ceil(Math.abs(ev.deltaY) * ev.deltaFactor);
    const scrollDirection = (ev.deltaY >= 0) ? "up" : "down";
    if (!scrollSize || sliding) {
      return;
    }

    if (scrollDirection === 'up') {
      collectScrolls += scrollSize;
    } else {
      collectScrolls -= scrollSize;
    }

    setTimeout(() => {
      collectScrolls = 0;
    }, 200);

    const slide = childrenWithRef[index].ref.current;

    if (Math.abs(collectScrolls) >= minScrollToSlide) {
      if (scrollDirection === 'down' && index < slidesCount - 1) {
        if (!slide.isBottom) {
          return;
        }
        navigateToSlide(index + 1)
      } else if (scrollDirection === 'up' && index > 0) {
        if (!slide.isTop) {
          return;
        }
        navigateToSlide(index - 1);
      }
    }

    // if (event.deltaY < 0) {
    //   if (index > 0) {
        // if (!slide.isTop) {
        //   return;
        // }
        // setIndex(index - 1);
    //   }
    // }  else {
    //  if (index < slidesCount - 1) {
    //    if (!slide.isBottom) {
    //      return;
    //    }
    //   setIndex(index + 1)
    //  }
    // }
  }

  const getClassName = (idx) => {
    let className = 'horizontal-slide';
    if (idx === index) {
      className += ' selected';
    }
    return className;
  }

  return (
    <div className={`horizontal-slides-container`}>
      <div className={`horizontal-slides${show ? ' show-content' : ''}`} style={styles} onWheel={ev => onWheel(ev)}>
        { childrenWithRef.map((child, idx) => (
           <div className={getClassName(idx)} key={idx} data-index={idx}>
             {child}
           </div>
        )) }
      </div>
      <div className="horizontal-pagination-container">
        <div className="horizontal-pagination">
          <div className="tabs">
            <ul>
              {childrenArray.map((ch, idx) => (
                <li key={idx}>
                  <button onClick={() => navigateToSlide(idx)} role="tab" className={`slide-link ${index === idx ? 'selected' : ''}`}>
                    <span className="">{ ch.props.label ?? '' }</span>
                  </button>
                  <span className="pagination-tooltip">
                    <span className="tooltip-shadow"></span>
                    <span className="tooltip-background">
                      <span>{ch.props.title}</span>
                    </span>
                  </span>
                </li>
              ))}
            </ul>
            <button className={`current-tab${initial ? ' start' : ''}`} style={currentTabStyles} onClick={() => onStartClick()}>
              <i className="bi bi-chevron-left"></i>
              <i className="bi bi-chevron-right"></i>

              <span className="btn-content-wrapper">
                <span className="btn-content">Unsere Leistungen</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}