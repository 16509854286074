import { useEffect, useMemo } from "react";
import ReactDOM from 'react-dom';
import './Modal.scss';


export default function Modal({ children, onClose }) {
  const el = useMemo(() => document.createElement('div'), []);
  const container = useMemo(() => document.getElementById('modals'), []);

  useEffect(() => {
    container.className = 'modals-portal';
    el.className = 'modal-backdrop';
    el.onclick = ev => {
      if (ev.target.classList.contains('close-modal') /* || ev.target.classList.contains('modal-backdrop') */) {
        onClose && onClose();
      }
    };
    container.appendChild(el);
    return () =>  container.removeChild(el);
  }, [el, container, onClose]);

  const modalContainer = <div className="modal">
    <button className="close-modal">&#10006;</button>
    { children }
  </div>
  return ReactDOM.createPortal(modalContainer, el);
}