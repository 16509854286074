import { useState } from 'react';
import { LightBox } from 'react-lightbox-pack';
import './Gallery.scss';
import "react-lightbox-pack/dist/index.css";

import img1 from '../../images/gallery/Bild1.png';
import img4 from '../../images/gallery/Bild2.png';
import img2 from '../../images/gallery/Bild3.jpeg';
import img3 from '../../images/gallery/Bild4.jpeg';
import img5 from '../../images/gallery/Bild5.jpeg';
import img6 from '../../images/gallery/Build6.jpg';
import img7 from '../../images/gallery/Build7.jpg';
import img8 from '../../images/gallery/Build8.jpg';

const data = [
  {
    id: 1,
    image: img1,
    title: '',
    description: '',
  },
  {
    id: 2,
    image: img2,
    title: '',
    description: '',
  },
  {
    id: 3,
    image: img3,
    title: '',
    description: '',
  },
  {
    id: 4,
    image: img4,
    title: '',
    description: '',
  },
  {
    id: 5,
    image: img5,
    title: '',
    description: '',
  },
  {
    id: 6,
    image: img6,
    title: '',
    description: '',
  },
  {
    id: 7,
    image: img7,
    title: '',
    description: '',
  },
  {
    id: 8,
    image: img8,
    title: '',
    description: '',
  },
];

export default function GalleryPage() {
  const [toggle, setToggle] =  useState(false);
	const [sIndex, setSIndex] =  useState(0);

  const  lightBoxHandler  = (state, sIndex) => {
		setToggle(state);
		setSIndex(sIndex);
	};

  const classes = ['wide', 'wide', 'tall' , 'big', 'wide'];

  return (
    <>
      <div className="gallery-page mt-5 pt-5">
            <div className="container">
          <h1 className="section-title text-center">Referenzen</h1>
          <div class="grid-wrapper">
            { data.map((item, index) => (
                <div key={index} className={classes[index]}>
                  <img className="img-fluid mb-3"
                    key={item.id}
                    src={item.image}
                    alt={item.title}
                    onClick={() => lightBoxHandler(true, index)} />
                </div>
              ))}
          </div>
        </div>
            
            <LightBox
              state={toggle}
          event={lightBoxHandler}
          data={data}
          imageWidth="60vw"
          imageHeight="70vh"
          thumbnailHeight={50}
          thumbnailWidth={50}
          setImageIndex={setSIndex}
          imageIndex={sIndex}
            />
          </div>
    </>
  );
}
