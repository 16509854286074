import MorphButtons from "../Button/MorphButton";
import Button from "../Button/Button";
import { useLocation } from "react-router";

export default function LeftControls() {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <div className="left-controls">
      <div className="control">
        <MorphButtons color="primary" position="left">
          {!isHomePage && <Button to="/" icon="house">HOME</Button>}
          <Button href="tel:+4904216485770" secondary={isHomePage} icon="telephone">Jetzt Termin vereinbaren</Button>
        </MorphButtons>
      </div>
    </div>
  );
}