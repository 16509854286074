import { useState, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Button from '../../components/Button/Button';
import VerticalSlide from '../../components/VerticalSlides/VerticalSlide';
import VerticalSlides from '../../components/VerticalSlides/VerticalSlides';

function Form1(props) {
  return (
    <form className="row" onSubmit={ev => ev.preventDefault()}>
      <div className="col-12 col-md-4 mb-1">
        <div className="form-floating">
          <input className="form-control" id="plz-or" />
          <label htmlFor="plz-or">PLZ/Ort</label>
        </div>
      </div>
      <div className="col-12 col-md-4 mb-1">
        <div className="form-floating">
          <input type="number" name="raius" id="radius" className="form-control" />
          <label htmlFor="radius">Radius (km)</label>
        </div>
      </div>
      <div className="col-12 col-md-4 pb-1" style={{minHeight: '58px'}}>
        <Button color="primary" className="h-100 w-100" rounded={false} onClick={() => props.onSubmit && props.onSubmit()}>Verfügbarkeit prüfen</Button>
      </div>
    </form>
  );
}

function Form2() {
  return (
    <form className="row">
      <div className="col-12 col-md-4 mb-1">
        <div className="form-floating">
          <input className="form-control" id="firma" />
          <label htmlFor="firma">Firma</label>
        </div>
      </div>
      <div className="col-12 col-md-4 mb-1">
        <div className="form-floating">
          <input className="form-control" id="name" />
          <label htmlFor="name">Vorname und Name</label>
        </div>
      </div>
      <div className="col-12 col-md-4 mb-1">
        <div className="form-floating">
          <input className="form-control" id="address" />
          <label htmlFor="address">Straße und Hausnummer</label>
        </div>
      </div>
      <div className="col-12 col-md-4 mb-1">
        <div className="form-floating">
          <input className="form-control" id="plz" />
          <label htmlFor="plz">PLZ</label>
        </div>
      </div>
      <div className="col-12 col-md-4 mb-1">
        <div className="form-floating">
          <input className="form-control" id="ort" />
          <label htmlFor="ort">Ort</label>
        </div>
      </div>
      <div className="col-12 col-md-4 mb-1">
        <div className="form-floating">
          <input className="form-control" id="phone" />
          <label htmlFor="phone">Telefon</label>
        </div>
      </div>
      <div className="col-12 col-md-4 mb-1">
        <div className="form-floating">
          <input className="form-control" id="mail" />
          <label htmlFor="mail">E-Mail</label>
        </div>
      </div>
      <div className="col-12 col-md-4 mb-1">
        <div className="form-floating">
          <input className="form-control" id="concern" />
          <label htmlFor="concern">Ihr Anliegen</label>
        </div>
      </div>
      <div className="col-12 col-md-4 pb-1" style={{minHeight: '58px'}}>
        <Button color="primary" className="h-100 w-100" rounded={false}>Anfrage abschicken</Button>
      </div>
    </form>
  );
}

function Form() {
  const [ step, setStep ] = useState(1);

  return (
    <TransitionGroup>
      <CSSTransition timeout={1000} classNames="fade">
        <div key={step}>
          { step === 1 ? <Form1 onSubmit={() => setStep(2)} /> : <Form2 /> }
        </div>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default function PartnerPage() {
  const slideRef = useRef();
  return (
    <div>
      <VerticalSlides ref={slideRef}>
        <VerticalSlide backgroundColor="rgba(var(--primary-rgb), 0.3)">
          <h2 className="section-title">PARTNER WERDEN</h2>
          <p className="section-description">Werden Sie Dienstleistungspartner in Ihrer Region und profitieren Sie von unserem Netzwerk.</p>
          <p className="section-description">Als Partner sind Sie nicht nur auf der Website als gelistet sondern bekommen Aufträge direkt über den Dienstleistungsverteiler zugeschickt.</p>
          <p className="section-description">Sie bekommen fertige Aufträge und profitieren von der “123 Regenrinnefrei” Reichweite.</p>
          <p className="section-description">Werden Sie jetzt Partner!</p>
          <Button className="next-button" color="secondary" onClick={() => slideRef.current.next()}>JETZT KAPAZITÄT <br/> IN MEINER REGION ÜBERPRÜFEN</Button>
        </VerticalSlide>
        <VerticalSlide backgroundColor="rgba(var(--quaternary-rgb), 0.3)">
          <h2 className="section-title">Kapazität in Ihrer Region</h2>
            <Form />
        </VerticalSlide>
      </VerticalSlides>
    </div>
  )
}