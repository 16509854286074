import ButtonCreator from "./ButtonCreator";

export default function Button(props) {
  let className = 'btn';
  if (props.className) {
    className += ` ${props.className || ''}`;
  }
  if (props.color) {
    className += ` ${props.color || ''}`;
  }
  const styles = {};
  if (!props.color) {
    styles.color = 'var(--primary)'
  }
  return (
    <ButtonCreator {...props} className={className}>
      <span className="btn-content-wrapper">
        <span className="btn-content" style={styles}>{props.children}</span>
      </span>
    </ButtonCreator>
  );
}