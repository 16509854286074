import { useState } from 'react';
import Button from '../Button/Button';
import './Configurator.scss';

import optionHouse1 from '../../images/semi-detached-house.png';
import optionHouse2 from '../../images/detached-house.png';
import optionHouse3 from '../../images/apartment-building.png';
import optionHouse4 from '../../images/business-building.png';
import electricityYes from '../../images/electricity-yes.png';
import electricityNo from '../../images/electricity-no.png';
import oneXYear from '../../images/1xYear.png';
import twoXYear from '../../images/2xYear.png';
import onlyOne from '../../images/only-one-time.png';
import ContactForm from '../ContactForm/ContactForm';
import MainTitle from '../MainTitle/MainTitle';
import { contactUrl } from '../../utils/contansts';
import { getBody } from '../../utils/functions';
import SuccessModal from '../Modal/SuccessModal';
import ErrorModal from '../Modal/ErrorModal';
import Modal from '../Modal/Modal';

function Option1(props) {
  const options = ['Doppelhaushälfte', 'Einfamilienhaus', 'Mehrfamilienhaus', 'Gewerbe'];
  const images = [optionHouse1, optionHouse2, optionHouse3, optionHouse4];

  return (
    <div className="mb-3 h-100 d-flex flex-column">
      <div className="fs-4 fw-bold mb-4">Um welchen Gebäudetyp handelt es sich?</div>
      <div className="configurator-select ">
        {options.map((opt, idx) => (
          <div key={idx} className="configurator-option" onClick={() => props.onSelect && props.onSelect(opt)}>
            <div className="option-image">
              <img src={images[idx]} alt={opt} />
            </div>
            <div className="option-value">{opt}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

function Option2(props) {
  const [ length, setLength ] = useState(undefined);

  return (
    <div className="mb-3 h-100 d-flex flex-column">
      <div className="fs-4 fw-bold mb-4">Welche Länge hat Ihre Regenrinne?</div>
      <div className="d-flex justify-content-center align-items-center flex-1">
        <div>
          <div className="form-floating mb-3">
            <input type="number" className="form-control" id="length" 
              onChange={ev => setLength(ev.target.value)}
              onInput={ev => setLength(ev.target.value)} />
            
            <label htmlFor="length">Länge (m)</label>
          </div>
          <Button className="w-100" rounded={false} color="primary" onClick={() => length && props.onSelect && props.onSelect(length) }>Fortsetzen</Button>
        </div>
      </div>
    </div>
  )
}

function Option3(props) {
  const [ length, setLength ] = useState(undefined);

  return (
    <div className="mb-3 h-100 d-flex flex-column">
      <div className="fs-4 fw-bold mb-4">In ca. welcher Höhe befindet sich die Regenrinne?</div>
      <div className="d-flex justify-content-center align-items-center flex-1">
        <div>
          <div className="form-floating mb-3">
            <input type="number" className="form-control" id="height"
              onChange={ev => setLength(ev.target.value)}
              onInput={ev => setLength(ev.target.value)} />
            
            <label htmlFor="height">Höhe (m)</label>
          </div>
          <Button className="w-100" rounded={false} color="primary" onClick={() => !!length && props.onSelect && props.onSelect(length) }>Fortsetzen</Button>
        </div>
      </div>
    </div>
  )
}


function Option4(props) {
  return (
    <div className="mb-3 h-100 d-flex flex-column">
      <div className="fs-4 fw-bold mb-4">Ist ein Stromanschluss vorhanden?</div>
      <div className="configurator-select ">
        <div className="configurator-option" onClick={() => props.onSelect && props.onSelect(true)}>
          <div className="option-image">
            <img src={electricityYes} alt="Ja" />
          </div>
          <div className="option-value">Ja</div>
        </div>
        <div className="configurator-option" onClick={() => props.onSelect && props.onSelect(false)}>
          <div className="option-image">
            <img src={electricityNo} alt="Nein" />
          </div>
          <div className="option-value">Nein</div>
        </div>
      </div>
    </div>
  )
}

function Option5(props) {
  const options = ['1x im Jahr', '2x im Jahr', 'einmalig'];
  const images = [oneXYear, twoXYear, onlyOne];
  return (
    <div className="mb-3 h-100 d-flex flex-column">
      <div className="fs-4 fw-bold mb-4">Welchen Reinigungsintervall bevorzugen Sie?</div>
      <div className="configurator-select ">
        {options.map((opt, idx) => (
          <div key={idx} className="configurator-option" onClick={() => props.onSelect && props.onSelect(opt)}>
            <div className="option-image">
              <img src={images[idx]} alt={opt} />
            </div>
            <div className="option-value">{opt}</div>
          </div>
        ))}
      </div>
    </div>
  )
}




export default function Configurator(props) {
  const [ step, setStep ] = useState(0);
  const [ length, setLength ] = useState();
  const [ height, setHeight ] = useState();
  const [ house, setHouse ] = useState();
  const [ electricity, setElectricity ] = useState();
  const [ periodicity, setPeriodicity ] = useState();
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showError, setShowError ] = useState(false);

  const _setStep = (s) => {
    setStep(s);
    props.onChangeStep && props.onChangeStep(s);
  }

  const onSubmit = (form) => {
    
    const from = '123 Dachrinnenfrei';
    const to = 'regenrinnenreinigung@bluebirdfacility.de';
    const subject = 'Neuer Vertrag generiert';
    const body = `
<h2>Kundeninformation</h2> <br />
<strong>Name: </strong> ${form.name}<br />
<strong>E-Mail Adresse: ${form.email}</strong> <br />
<strong>Telefonnummer: ${form.phone}</strong> <br />
<strong>Postleitzahl: ${form.zip}</strong> <br />

<h2>Gebäudetyp handelt</h2>
<strong>Gebäudetyp handelt: ${house}</strong> <br />
<strong>Welche Länge hat Ihre Regenrinne: ${length}m</strong> <br />
<strong>Höhe befindet sich die Regenrinne: ${height}m</strong> <br />
<strong>Ist ein Stromanschluss vorhanden: ${electricity ? 'Yes' : 'No'}m</strong> <br />
<strong>Welchen Reinigungsintervall bevorzugen Sie: ${periodicity}m</strong> <br />
    `;
    const data = getBody({ from, to, subject, body });

    fetch(contactUrl, {
      method: 'POST',
      body: data,
      headers: {'content-type': 'application/x-www-form-urlencoded'}
    })
    .then(() => {
      setHouse(undefined);
      setLength(undefined);
      setHeight(undefined);
      setElectricity(undefined);
      setPeriodicity(undefined);
      setStep(0);
      setShowSuccess(true)
    })
    .catch(() => setShowError(true))
  }

  const options = [
    <Option1 onSelect={val => {_setStep(step + 1); setLength(val)}} />,
    <Option2 onSelect={val => {_setStep(step + 1); setHeight(val)}} />,
    <Option3 onSelect={val => {_setStep(step + 1); setHouse(val)}} />,
    <Option4 onSelect={val => {_setStep(step + 1); setElectricity(val)}} />,
    <Option5 onSelect={val => {_setStep(step + 1); setPeriodicity(val)}} />,
    <ContactForm onSubmit={ev => onSubmit(ev)}/>,
  ];

  return (
    <div className="p-md-3 h-100 d-flex flex-column">

      <div className="flex-1 d-flex flex-column justify-content-center">
        <div className="steps">
          {/* <h3 className="configurator-title">MACHEN SIE JETZT EINEN TERMIN FÜR IHRE REGENRINNENREINIGUNG</h3> */}
          <MainTitle>MACHEN SIE JETZT EINEN TERMIN FÜR IHRE REGENRINNENREINIGUNG</MainTitle>

          {step === 0 && !house && <h5 className="promo">KOSTENLOSES ANGEBOT BINNEN 48h</h5>}

          { options[step] }
        </div>
      </div>
      <div className="progress">
        <button style={{visibility: step === 0 ? 'hidden' : 'visible' }} onClick={() => _setStep(step - 1)}><i className="bi bi-arrow-left-circle"></i></button>
        <div className="progress-bar"><div className="value" style={{width: `${step / 5 * 100}%`}}></div></div>
        <button style={{visibility: 'hidden'}}><i className="bi bi-arrow-right-circle"></i></button>
      </div>

      {showSuccess && <Modal onClose={() => setShowSuccess(false)}>
        <SuccessModal>
          <div className="fs-4 text-success mb-2">Danke, dass Sie uns kontaktiert haben</div>
          <div>Sie erhalten in Kürze eine Antwort</div>
        </SuccessModal>
      </Modal>}
      {showError && <Modal onClose={() => setShowError(false)}>
        <ErrorModal>
          <div className="fs-4 text-danger" style={{marginTop: '-3rem'}}>Ein Fehler ist aufgetreten</div>
        </ErrorModal>
      </Modal>}
    </div>
  );
}