const primaryRgb = [115, 197, 194];
const secondaryRgb = [214, 86, 64];
const tertiaryRgb = [191, 179, 124];
const quaternaryRgb = [52, 37, 96];

function rgba(...values) {
  let comp = `${values[0]}, ${values[1]}, ${values[2]}`;
  if (values[3]) {
    comp += `, ${values[3]}`;
  }
  return values[3] ? `rgba(${comp})` : `rgb(${comp})`;
}

export const colors = {
  primary: rgba(...primaryRgb),
  secondary: rgba(...secondaryRgb),
  tertiary: rgba(...tertiaryRgb),
  quaternary: rgba(...quaternaryRgb),

  alphaPrimary: (alpha = 1) => rgba(...primaryRgb, alpha),
  alphaSecondary: (alpha = 1) => rgba(...secondaryRgb, alpha),
  alphaTertiary: (alpha = 1) => rgba(...tertiaryRgb, alpha),
  alphaQuaternary: (alpha = 1) => rgba(...quaternaryRgb, alpha),
}

export const contactUrl = `https://api.jesamconsulting.com/.netlify/functions/send-email`