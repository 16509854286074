import React from 'react';
import './Button.scss';
import ButtonCreator from './ButtonCreator';

function IconCreator(props) {
  const fontFamilyClassName = props.fontFamily || 'bi';
  const icon = props.icon;
  const iconInClass = typeof props.iconInClass === 'undefined' ? true : props.iconInClass;
  const prefix = props.iconPrefix || 'bi';
  const separator = props.separator || (prefix ? '-' : '');

  let className = fontFamilyClassName;
  if (iconInClass) {
    className += ` ${prefix}${separator}${icon}`;
  }

  return <i className={className}>{!iconInClass && icon}</i>
}

export default function MorphButtons(props) {
  const children = React.Children.toArray(props.children);
  let main = children[0];
  let secondary = children[1];

  let position = 'right';
  if (props.position === 'left') {
    position = 'left';
  }

  if (main && main.props.secondary) {
    const tmp = main;
    main = secondary;
    secondary = tmp;
  }
  
  return (
    <div className={`btn-container btn-${position} closed ${props.color}`}>
      <svg viewBox="0 0 53 99" xmlSpace="preserve" className={!main && !!secondary ? 'only-secondary' : ''}>      
        {main && <path className="main-shadow" d="M25.5,2.5C39,2.5,50,13.5,50,27S39,51.5,25.5,51.5S1,40.5,1,27S11.9,2.5,25.5,2.5z"/>}
        {secondary && <path className="secondary-shadow" d="M26.1,61.5c9.1,0,16.5,7.4,16.5,16.5s-7.4,16.5-16.5,16.5S9.6,87.1,9.6,78S17,61.5,26.1,61.5 z"/>}
    
        {secondary && main && (
          <>
            <path className="main-gooey" d="M 31 57 c -0.2 -3 1.6 -5.9 5.4 -8.7 c 7.8 -4.1 13.2 -12.3 13.2 -21.7 c 0 -13.5 -11 -24.5 -24.5 -24.5 S 1.2 13.5 1.2 27 c 0 9.4 5.3 17.6 13.2 21.7 c 3.5 2.7 5.2 5.7 5.6 8.3 Q 21 61 26 61 Q 30 61 31 57"/>
            <path className="secondary-gooey" d="M 20 57 c 0 2.5 -1.2 5 -3.5 7.2 c -4.1 3 -6.8 7.9 -6.8 13.4 c 0 9.1 7.4 16.5 16.5 16.5 S 42.2 87.1 42.2 78 c 0 -5.3 -2.5 -9.9 -6.3 -13 c -2.9 -1 -4.9 -5 -4.9 -8 Q 29 54 26 54 Q 21 54 20 57"/>
          </>
        )}
      </svg>
    
      
      {main && <ButtonCreator {...main.props} className="main-btn"><IconCreator {...main.props} /></ButtonCreator>}
    
      {secondary && <ButtonCreator {...secondary.props} className="secondary-btn"><IconCreator {...secondary.props} /></ButtonCreator>}
      
      {main && <span className="main-label"><span>{ main.props.children }</span></span>}
      {secondary && <span className="secondary-label"><span>{ secondary.props.children }</span></span>}
   </div>
  )
}