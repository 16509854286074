import Lottie from "lottie-react";
import checkSuccessLottie from '../../lotties/check.json';

export default function SuccessModal({ children }) {
  return (
    <div className="modal-content">
      <Lottie className="modal-icon" animationData={checkSuccessLottie} loop={false}></Lottie>

      <div className="text-center">
        {children}
      </div>
    </div>
  );
}