import { Link } from "react-router-dom"

export default function ButtonCreator(props) {
  let rounded = true;
  if (typeof props.rounded !== 'undefined') {
    rounded = props.rounded
  }

  let className = props.className || '';
  if (!rounded) {
    className += ' not-rounded';
  }
  if (props.href) {
    return <a href={props.href} className={className} onClick={ev => props.onClick && props.onClick(ev)}>{props.children}</a>
  }
  if (props.to) {
    return <Link to={props.to} className={className} onClick={ev => props.onClick && props.onClick(ev)}>{props.children}</Link>
  }
  return <button className={className} onClick={ev => props.onClick && props.onClick(ev)}>{props.children}</button>
}