import MorphButtons from "../Button/MorphButton";
import Button from "../Button/Button";
import Sidenav from '../Sidenav/Sidenav';

export default function RightControls() {
  const toggleSidenav = () => {
    Sidenav.sidenav.toggle();
  };
  return (
    <div className="right-controls">
      <div className="control">
        <MorphButtons color="primary">
          <Button icon="list" onClick={() => toggleSidenav()}>Menu öffnen</Button>
          <Button to="/kontakt" icon="info">Kontakt</Button>
        </MorphButtons>
      </div>
    </div>
  );
}