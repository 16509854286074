import React from "react";

export default class HorizontalSlide extends React.Component {
  scrollerRef = React.createRef();

  get isBottom () {
    const scroller = this.scrollerRef.current;
    return scroller.scrollHeight - scroller.scrollTop === scroller.clientHeight;
  }

  get isTop() {
    const scroller = this.scrollerRef.current;
    return scroller.scrollTop === 0;
  }

  render() {
    return (
      <div className="slide-scroller" ref={this.scrollerRef}>{ this.props.children }</div>
    )
  }
}