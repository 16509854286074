import { colors } from "../../utils/contansts";

export const shapes = [
  {
    path: 'M -25.298 124.697 C -78.098 216.65 212.6 473.468 288.8 544.357 C 372.2 621.983 511.2 505.758 620.3 525.539 C 750.6 549.169 860.3 725.162 987.3 686.136 C 1089 654.915 1168 523.828 1173 411.026 C 1178 287.531 841.173 393.965 740.273 331.523 C 597.273 242.104 637.564 160.912 476.864 203.948 C 389.464 227.364 34.402 20.555 -25.298 124.697 Z',
    pathAlt: 'M -25.298 124.697 C -78.098 216.65 212.6 473.468 288.8 544.357 C 372.2 621.983 520.301 584.633 629.401 604.414 C 759.701 628.044 860.3 725.162 987.3 686.136 C 1089 654.915 1168 523.828 1173 411.026 C 1178 287.531 938.25 354.527 837.35 292.085 C 694.35 202.666 637.564 160.912 476.864 203.948 C 389.464 227.364 34.402 20.555 -25.298 124.697 Z',
    scaleX: 1,
    scaleY: 1,
    rotate: 0,
    tx: -30,
    ty: 0,
    bgColor: colors.alphaQuaternary(0.1),
    fill: {
      color: colors.alphaQuaternary(0.1),
      duration: 500,
      easing: 'linear'
    },
    animation: {
      path: {
        duration: 3000,
        easing: 'easeOutElastic',
        elasticity: 600
      },
      svg: {
        duration: 2000,
        easing: 'easeOutElastic'
      }
    }
  },
  {
    path: 'M 450.987 -36.087 C 398.187 55.866 212.6 473.468 288.8 544.357 C 372.2 621.983 520.301 584.633 629.401 604.414 C 759.701 628.044 435.588 1216.615 562.588 1177.589 C 664.288 1146.368 1168 523.828 1173 411.026 C 1178 287.531 938.25 354.527 837.35 292.085 C 694.35 202.666 889.358 -503.459 728.658 -460.423 C 641.258 -437.007 510.687 -140.229 450.987 -36.087 Z',
    pathAlt: 'M 450.987 -36.087 C 261.672 52.832 212.6 473.468 288.8 544.357 C 372.2 621.983 520.301 584.633 629.401 604.414 C 759.701 628.044 435.588 1216.615 562.588 1177.589 C 664.288 1146.368 1504.735 463.155 1173 411.026 C 1050.902 391.84 938.25 354.527 837.35 292.085 C 694.35 202.666 889.358 -503.459 728.658 -460.423 C 641.258 -437.007 559.639 -87.12 450.987 -36.087 Z',
    scaleX: .4,
    scaleY: 1.4,
    rotate: 0,
    tx: 0,
    ty: -100,
    bgColor: colors.alphaQuaternary(0.1),
    fill: {
      color: colors.alphaQuaternary(0.1),
      duration: 500,
      easing: 'linear'
    },
    animation: {
      path: {
        duration: 2000,
        easing: 'easeOutElastic',
        elasticity: 600
      },
      svg: {
        duration: 2000,
        easing: 'easeOutElastic'
      }
    }
  },
  {
    path: 'M 383.8,163.4 C 335.8,352.3 591.6,317.1 608.7,420.8 625.8,524.5 580.5,626 647.3,688 714,750 837.1,760.5 940.9,661.5 1044,562.3 1041,455.8 975.8,393.6 909.8,331.5 854.2,365.4 784.4,328.1 714.6,290.8 771.9,245.2 733.1,132.4 694.2,19.52 431.9,-25.48 383.8,163.4 Z',
    pathAlt: 'M 383.8,163.4 C 345.5,324.9 591.6,317.1 608.7,420.8 625.8,524.5 595.1,597 647.3,688 699.5,779 837.1,760.5 940.9,661.5 1044,562.3 1068,444.4 975.8,393.6 884,342.8 854.2,365.4 784.4,328.1 714.6,290.8 820.3,237.2 733.1,132.4 645.9,27.62 422.1,1.919 383.8,163.4 Z',
    scaleX: .7,
    scaleY: 1.1,
    rotate: -20,
    tx: 200,
    ty: 200,
    bgColor: colors.alphaQuaternary(0.1),
    fill: {
      color: colors.alphaQuaternary(0.1),
      duration: 500,
      easing: 'linear'
    },
    animation: {
      path: {
        duration: 3000,
        easing: 'easeOutElastic',
        elasticity: 600
      },
      svg: {
        duration: 2500,
        easing: 'easeOutElastic'
      }
    }
  },
  {
    path: 'M 262.9,252.2 C 210.1,338.2 212.6,487.6 288.8,553.9 372.2,626.5 511.2,517.8 620.3,536.3 750.6,558.4 860.3,723 987.3,686.5 1089,657.3 1168,534.7 1173,429.2 1178,313.7 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z',
    pathAlt: 'M 262.9,252.2 C 210.1,338.2 273.3,400.5 298.5,520 323.7,639.6 511.2,537.2 620.3,555.7 750.6,577.8 872.2,707.4 987.3,686.5 1102,665.6 1218,547.8 1173,429.2 1128,310.6 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z',
    scaleX: 1.5,
    scaleY: 1,
    rotate: -20,
    tx: 0,
    ty: -50,
    bgColor: colors.alphaQuaternary(0.1),
    fill: {
      color: colors.alphaQuaternary(0.1),
      duration: 500,
      easing: 'linear'
    },
    animation: {
      path: {
        duration: 3000,
        easing: 'easeOutQuad',
        elasticity: 600
      },
      svg: {
        duration: 3000,
        easing: 'easeOutElastic'
      }
    }
  },
  {
    path: 'M 247.6,239.6 C 174.3,404.5 245.5,601.9 358.5,624.3 471.5,646.6 569.1,611.6 659.7,655.7 750.4,699.7 1068,687.6 1153,534.4 1237,381.1 1114,328.4 1127,227.4 1140,126.3 1016,51.08 924.6,116.8 833.8,182.5 928.4,393.8 706.8,283.5 485.2,173.1 320.8,74.68 247.6,239.6 Z',
    pathAlt: 'M 247.6,239.6 C 174.3,404.5 271.3,550.3 358.5,624.3 445.7,698.3 569.1,611.6 659.7,655.7 750.4,699.7 1145,699 1153,534.4 1161,369.8 1114,328.4 1127,227.4 1140,126.3 1016,51.08 924.6,116.8 833.8,182.5 894.5,431 706.8,283.5 519.1,136 320.8,74.68 247.6,239.6 Z',
    scaleX: 1.8,
    scaleY: 1.5,
    rotate: 0,
    tx: 250,
    ty: 50,
    bgColor: colors.alphaQuaternary(0.1),
    fill: {
      color: colors.alphaQuaternary(0.1),
      duration: 500,
      easing: 'linear'
    },
    animation: {
      path: {
        duration: 3000,
        easing: 'easeOutElastic',
        elasticity: 600
      },
      svg: {
        duration: 2000,
        easing: 'easeOutExpo'
      }
    }
  },
  // footer shape:
  {
    path: 'M 262.9,252.2 C 210.1,338.2 212.6,487.6 288.8,553.9 372.2,626.5 511.2,517.8 620.3,536.3 750.6,558.4 860.3,723 987.3,686.5 1089,657.3 1168,534.7 1173,429.2 1178,313.7 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z',
    pathAlt: 'M 262.9,252.2 C 210.1,338.2 273.3,400.5 298.5,520 323.7,639.6 511.2,537.2 620.3,555.7 750.6,577.8 872.2,707.4 987.3,686.5 1102,665.6 1218,547.8 1173,429.2 1128,310.6 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z',
    scaleX: 2.5,
    scaleY: 2,
    rotate: 0,
    tx: 0,
    ty: -50,
    bgColor: colors.alphaQuaternary(0.1),
    fill: {
      color: colors.alphaQuaternary(0.1),
      duration: 500,
      easing: 'linear'
    },
    animation: {
      path: {
        duration: 3000,
        easing: 'easeOutQuad',
        elasticity: 600
      },
      svg: {
        duration: 3000,
        easing: 'easeOutElastic'
      }
    }
  }
];