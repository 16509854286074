import React from "react";
import './Header.scss';

import logo from '../../images/123-regenrinnenfrei.png';
import { Link } from "react-router-dom";

const toHome = new Event('tohome');
export default class Header extends React.Component {
  headerRef = React.createRef();
  linkRef = React.createRef();

  state = {
    sticky: false
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop === 0) {
        this.setState({ sticky: false })
      } else if (!this.state.sticky) {
        this.setState({ sticky: true })
      }
    });

    Header.header = this;
  }

  hide() {
    this.headerRef.current.classList.add('hidden');
  }

  show() {
    this.headerRef.current.classList.remove('hidden');
  }

  render() {
    return (
      <header ref={this.headerRef} className={this.state.sticky && 'sticky'}>
        <Link onClick={() => window.dispatchEvent(toHome)} ref={this.linkRef} to="/"><img src={logo} alt="" /></Link>
      </header>
    );
  }
}