import {useState, useRef, useEffect} from 'react'
import Button from '../Button/Button';

export default function ContactForm(props) {
  const [ name, setName ] = useState();
  const [ email, setEmail ] = useState();
  const [ phone, setPhone ] = useState();
  const [ zip, setZip ] = useState();
  const [ content, setContent ] = useState();

  const formRef = useRef();

  useEffect(() => {
    const isValid = formRef.current.checkValidity();
    if (isValid) {
      formRef.current.classList.remove('invalid')
    } else {
      formRef.current.classList.add('invalid')
    }
  });

  let showTitle = true;
  if (typeof props.showTitle !== 'undefined') {
    showTitle = props.showTitle;
  }

  return (
    <form ref={formRef} className="p-3 rounded lg-form text-body" onSubmit={ev => {
      ev.preventDefault();
      setName('');
      setEmail('');
      setPhone('');
      setZip('');
      setContent('');
      const data = {name, email, phone, zip, content };
      props.onSubmit && props.onSubmit(data);
    }}>
      {showTitle && <p className="fs-5 fw-bold mb-3">
        Kontaktieren Sie uns! <br/>
        Wir melden uns binnen 24h bei Ihnen.
      </p>}
      <div className="form-floating mb-2">
        <input type="text" className="form-control" id="name" onInput={ev => setName(ev.target.value)} required/>
        <label htmlFor="name">Name</label>
      </div>
      <div className="form-floating mb-2">
        <input type="email" className="form-control" id="email" onInput={ev => setEmail(ev.target.value)} required/>
        <label htmlFor="email">E-Mail Adresse</label>
      </div>
      <div className="form-floating mb-2">
        <input type="tel" className="form-control" id="tel" onInput={ev => setPhone(ev.target.value)} required/>
        <label htmlFor="tel">Telefonnummer</label>
      </div>
      <div className="form-floating mb-2">
        <input type="text" className="form-control" id="zip" onInput={ev => setZip(ev.target.value)} required/>
        <label htmlFor="zip">Postleitzahl</label>
      </div>
      {props.showContentField && <div className="form-floating mb-2">
        <textarea className="form-control" id="content" onInput={ev => setContent(ev.target.value)} rows={3} style={{height: 'unset'}} required></textarea>
        <label htmlFor="content">Ihr Anliegen</label>
      </div> }
      <div>
        <Button color="primary" className="w-100" rounded={false}>Anfrage abschicken</Button>
      </div>
    </form>
  )
}