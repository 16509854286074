import anime from 'animejs';
import React, { useEffect, useRef } from 'react';
import Button from '../../components/Button/Button';
import VerticalSlide from '../../components/VerticalSlides/VerticalSlide';
import VerticalSlides from '../../components/VerticalSlides/VerticalSlides';
import { shapes } from './animations';
import './Services.scss';

function initShapeLoop(el, pos, loop = false) {
  pos = pos || 0;
  anime.remove(el);
  return anime({
    targets: el,
    easing: 'linear',
    d: [{ value: shapes[pos].pathAlt, duration: 1500 }, { value: shapes[pos].path }],
    loop,
    fill: {
      value: shapes[pos].fill.color,
      duration: shapes[pos].fill.duration,
      easing: shapes[pos].fill.easing
    },
    direction: 'alternate'
  })
};

function setShapeLoop(el, pos) {
  anime.remove(el);
  anime({
    targets: el,
    duration: shapes[pos].animation.path.duration,
    easing: shapes[pos].animation.path.easing,
    elasticity: shapes[pos].animation.path.elasticity || 0,
    d: shapes[pos].path,
    fill: {
      value: shapes[pos].fill.color,
      duration: shapes[pos].fill.duration,
      easing: shapes[pos].fill.easing
    },
    complete: () => {
      initShapeLoop(el, pos);
    }
  })
}

function setMorphing(wrap, svg, path, pos) {
  wrap.style.backgroundColor = shapes[pos].bgColor;
  const classNames = ['primary-mask', 'secondary-mask', 'tertiary-mask', 'quaternary-mask'];
  document.body.classList.remove(...classNames);
  document.body.classList.add(classNames[pos])
  anime.remove(svg);
  anime({
    targets: svg,
    duration: shapes[pos].animation.svg.duration,
    easing: shapes[pos].animation.svg.easing,
    scaleX: shapes[pos].scaleX,
    scaleY: shapes[pos].scaleY,
    translateX: shapes[pos].tx+'px',
    translateY: shapes[pos].ty+'px',
    rotate: shapes[pos].rotate+'deg'
  });
  setShapeLoop(path, pos);
}

export default function ServicesPage() {
  const wrapRef = useRef();
  const svgRef = useRef();
  const pathRef = useRef();
  const slidesRef = useRef();

  useEffect(() => {
    setMorphing(wrapRef.current, svgRef.current, pathRef.current, 0);
    return () => {
      const classNames = ['primary-mask', 'secondary-mask', 'tertiary-mask', 'quaternary-mask'];
      document.body.classList.remove(...classNames);
    }
  }, []);

  const onChangeSlide = (slide) => {
    setMorphing(wrapRef.current, svgRef.current, pathRef.current, slide);
  }

  return (
    <div className="text-white">
      <div ref={wrapRef} className="morph-wrap">
				<svg ref={svgRef} className="morph" width="1400" height="770" viewBox="0 0 1400 770">
					<path ref={pathRef} d="M 262.9,252.2 C 210.1,338.2 212.6,487.6 288.8,553.9 372.2,626.5 511.2,517.8 620.3,536.3 750.6,558.4 860.3,723 987.3,686.5 1089,657.3 1168,534.7 1173,429.2 1178,313.7 1096,189.1 995.1,130.7 852.1,47.07 658.8,78.95 498.1,119.2 410.7,141.1 322.6,154.8 262.9,252.2 Z"/>
				</svg>
			</div>
      <VerticalSlides ref={slidesRef} onChangeSlide={slide => onChangeSlide(slide)}>
        <VerticalSlide>
          <h2 className="section-title">Dachrinnenreinigung und Dachinspektion</h2>
          <p className="section-content">
            Um Wasserschäden vorzubeugen, sollten die Dachrinnen regelmäßig
            gereinigt und das Dach auf Schäden überprüft werden. Die Kosten der
            Dachrinnenreinigung können auch auf die Mieter umgelegt werden, wenn
            beispielsweise regelmäßige Reinigungsintervalle aufgrund des
            Baumbestandes notwendig sind.
          </p>
          <Button className="next-button" onClick={() => slidesRef.current.next()}>Weitere Leistungen</Button>
        </VerticalSlide>
        <VerticalSlide>
          <h2 className="section-title">Reparatur von Löchern und Rissen in der Dachrinne</h2>
          <p className="section-content">
            Undichtigkeiten in der Dachrinne können zu störenden Tropfgeräuschen
            führen und uns manchmal auch um unseren Nachtschlaf bringen. Löcher
            über Gehwegen und Eingängen sind zudem für Passanten und
            insbesondere auch für Kunden eine unangenehme Angelegenheit.
          </p>
          <Button className="next-button" onClick={() => slidesRef.current.next()}>Weitere Leistungen</Button>
        </VerticalSlide>
        <VerticalSlide>
          <h2 className="section-title">Lösen von Fallrohrverstopfungen und Verstopfungen der Grundleitung</h2>
          <p className="section-content">
            Sind die Fallrohre verstopft, kann das Regenwasser nicht mehr aus der
            Dachrinne abfließen und es kommt zu deren Überlaufen. Hierdurch bilden
            sich sogenannte „Regenvorhänge“, die Schäden an der Fassade und am
            Mauerwerk verursachen können. Häufig dringen diese Wassermassen auch
            durch geschlossene Fenster in Wohnbereiche ein.
          </p>
          <Button to="/kontakt" className="next-button" color="secondary">JETZT TERMIN VEREINBAREN</Button>
        </VerticalSlide>
      </VerticalSlides>
    </div>
  );
}