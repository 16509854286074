import React from 'react';
import './MainTitle.scss';

export default function MainTitle(props) {
  const propClassName = props.className || '';
  return <div className={`main-title-container ${propClassName}`}>
    <h1 className="main-title">{props.children}</h1>
    <span className="main-title-shadow shadow-1"><span className="inner">{props.children}</span></span>
    <span className="main-title-shadow shadow-2"><span className="inner">{props.children}</span></span>
  </div>
}