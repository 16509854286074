import Header from './components/Header/Header';
import HomePage from './views/Home/Home';
import { Routes, Route, useLocation  } from 'react-router-dom'
import ServicesPage from './views/Services/Services';
import ContactPage from './views/Contact/Contact';
import Sidenav from './components/Sidenav/Sidenav';
import RightControls from './components/RightControls/RightControls';
import LeftControls from './components/LeftControls/LeftControls';
// import { useRef } from 'react';
// import anime from 'animejs';
import './App.scss';
import RepairPage from './views/Repair/Repair';
import PartnerPage from './views/Partner/Partner';
import { useEffect, useRef } from 'react';
import ImpressumPage from './views/Impresum/Impressum';
import GalleryPage from './views/Gallery/Galley';


// const dBottom = "M 0 600 C 0 600 0 300 0 600 C 53.4119 600 148 600 146 600 C 185.1762 600 210.1167 600 252 600 C 293.8833 600 352.7094 600 402 600 C 451.2906 600 491.0458 600 526 600 C 560.9542 600 591.1073 600 630 600 C 668.8927 600 716.5249 600 772 600 C 827.4751 600 890.793 600 939 600 C 987.207 600 1020.3029 600 1059 600 C 1097.6971 600 1141.9954 600 1181 600 C 1220.0046 600 1253.7156 600 1296 600 C 1338.2844 600 1389.1422 600 1440 600 C 1440 300 1440 600 1440 600 Z";
// const dFilled = "M 0 600 C 0 0 0 0 0 0 C 53.4119 0 148 0 146 0 C 185.1762 0 210.1167 0 252 0 C 293.8833 0 352.7094 0 402 0 C 451.2906 0 491.0458 0 526 0 C 560.9542 0 591.1073 0 630 0 C 668.8927 0 716.5249 0 772 0 C 827.4751 0 890.793 0 939 0 C 987.207 0 1020.3029 0 1059 0 C 1097.6971 0 1141.9954 0 1181 0 C 1220.0046 0 1253.7156 0 1296 0 C 1338.2844 0 1389.1422 0 1440 0 C 1440 0 1440 0 1440 600 Z";
// const dTop = "M 0 0 C 0 0 0 0 0 0 C 53.4119 0 148 0 146 0 C 185.1762 0 210.1167 0 252 0 C 293.8833 0 352.7094 0 402 0 C 451.2906 0 491.0458 0 526 0 C 560.9542 0 591.1073 0 630 0 C 668.8927 0 716.5249 0 772 0 C 827.4751 0 890.793 0 939 0 C 987.207 0 1020.3029 0 1059 0 C 1097.6971 0 1141.9954 0 1181 0 C 1220.0046 0 1253.7156 0 1296 0 C 1338.2844 0 1389.1422 0 1440 0 C 1440 0 1440 0 1440 0 Z";
// const dWaves = "M 0,600 C 0,600 0,300 0,300 C 53.41189866110402,268.3024017254599 106.82379732220804,236.60480345091983 146,244 C 185.17620267779196,251.39519654908017 210.11670937227188,297.88318792178063 252,311 C 293.8832906277281,324.11681207821937 352.7093651887044,303.86244486195784 402,307 C 451.2906348112956,310.13755513804216 491.0458298729103,336.6670326303879 526,371 C 560.9541701270897,405.3329673696121 591.1073153196545,447.46942461649064 630,394 C 668.8926846803455,340.53057538350936 716.5249088484716,191.45526890364943 772,191 C 827.4750911515284,190.54473109635057 890.7930492864591,338.70949976891166 939,389 C 987.2069507135409,439.29050023108834 1020.3028940056915,391.70673202070407 1059,377 C 1097.6971059943085,362.29326797929593 1141.9953746907752,380.46357214827196 1181,371 C 1220.0046253092248,361.53642785172804 1253.7156072312068,324.438979386208 1296,308 C 1338.2843927687932,291.561020613792 1389.1421963843966,295.78051030689596 1440,300 C 1440,300 1440,600 1440,600 Z";

// let prevLocation = '';

function App() {
  // const pathRef = useRef();
  // const svgRef = useRef();
  const location = useLocation();
  const headerRef = useRef();

  // const forwardAnimation = () => {
  //   svgRef.current.style.zIndex = 1000;
  //   anime({
  //     targets: pathRef.current,
  //     d: [
  //       {
  //         value: dWaves,
  //         duration: 1000 / 3,
  //         easing: 'linear'
  //       },
  //       {
  //         value: dFilled,
  //         duration: 1000 / 3,
  //         easing: 'linear'
  //       },
  //       {
  //         value: dTop,
  //         duration: 1000 / 3,
  //         easing: 'linear'
  //       },
  //       {
  //         value: dBottom,
  //         easing: 'linear',
  //         duration: 0
  //       }
  //     ],
  //     // easing: 'linear',
  //     delay: 0,
  //     // autoplay: false,
  //     loop: false
  //   });
  //   setTimeout(() => {
  //     // animation.restart();
  //     svgRef.current.style.zIndex = -1;
  //   }, 1100);
  // }

  // if (!!prevLocation && location.pathname !== prevLocation) {
  //   forwardAnimation();
  // }
  //  prevLocation = location.pathname;

  useEffect(() => {
    if (location.pathname === '/') {
      headerRef.current.show();
    } else {
      headerRef.current.hide();
    }
  })

  return (
    <div className="App">
      <Header ref={headerRef} />
      <Sidenav />
      <main>
        <Routes>
          <Route path="/unsere_leistungen" element={<ServicesPage />} />
          <Route path="/reperatur_and_reinigung" element={<RepairPage />} />
          <Route path="/kontakt" element={<ContactPage />} />
          <Route path="/partner" element={<PartnerPage />} />
          <Route path="/impressum" element={<ImpressumPage />} />
          <Route path="/referenzen" element={<GalleryPage />} />
          <Route path="/" element={ <HomePage /> } />
        </Routes>
      </main>

      <LeftControls/>
      <RightControls/>

      {/* <svg ref={svgRef} preserveAspectRatio="none" className="transition-wave" width="100%" height="100%" id="svg" viewBox="0 0 1440 600" xmlns="http://www.w3.org/2000/svg">
        <path ref={pathRef} d={dBottom} stroke="none" strokeWidth="0" fill="#ff0080ff"></path>
      </svg> */}
    </div>
  );
}

export default App;
