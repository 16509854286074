let lowestDelta;
let absDelta;
let nullLowestDeltaTimeout;

let touchX = 0, touchY = 0;
let touchTimeout;

function nullLowestDelta() {
  lowestDelta = null;
}

function cloneEvent(event, override){
  if (!override) {
    override = {};
  }
  return {...event, ...override};
}

export function mousewheel(event) {
  let origEvent = event.nativeEvent || event;
  if (origEvent.type === 'touchmove') {
    clearTimeout(touchTimeout);
    if (!touchX && !touchY) {
      touchY = origEvent.changedTouches[0].clientY;
      touchX = origEvent.changedTouches[0].clientX;
    }
    origEvent.deltaY = touchY - origEvent.changedTouches[0].clientY;
    origEvent.deltaX = touchX - origEvent.changedTouches[0].clientX;
    touchTimeout = setTimeout(() => {
      touchX = 0;
      touchY = 0;
    }, 20);
  }
  let ev = cloneEvent(origEvent);
  let deltaY = origEvent.deltaY * -1;
  let delta = deltaY;
  let deltaX = origEvent.deltaX;
  if (deltaY === 0) {
    delta = deltaX * -1;
  }
  if ( deltaY === 0 && deltaX === 0 ) {
    return;
  }

  /**
   * There are three delta modes
   * deltaMode 0 is by pixels, nothing to do
   * deltaMode 1 is by lines,
   * deltaMode 2 is by pages
   */
  if (origEvent.deltaMode === 1) {
    // TODO calculate line height of parent and convert delta variables to pixels
  } else if (origEvent.deltaMode === 2) {
    // TODO calculate page height and convert delta variables to pixels
  }

  absDelta = Math.max(Math.abs(deltaX), Math.abs(deltaY));

  if (!lowestDelta || absDelta < lowestDelta) {
    lowestDelta = absDelta;
  }

  delta = delta >= 1 ? Math.floor(delta / lowestDelta) : Math.ceil(delta / lowestDelta);
  deltaX = deltaX >= 1 ? Math.floor(deltaX / lowestDelta) : Math.ceil(deltaX / lowestDelta);
  deltaY = deltaY >= 1 ? Math.floor(deltaY / lowestDelta) : Math.ceil(deltaY / lowestDelta);

  ev.deltaX = deltaX;
  ev.deltaY = deltaY;
  ev.deltaFactor = lowestDelta;
  ev.deltaMode = 0;
  ev.wheelDelta = origEvent.wheelDelta || delta;
  if (nullLowestDeltaTimeout) {
    clearTimeout(nullLowestDeltaTimeout);
  }

  nullLowestDeltaTimeout = setTimeout(nullLowestDelta, 200);
  return ev;
}
