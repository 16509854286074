import React from "react";

export default class VerticalSlide extends React.Component {
  content = React.createRef();
  container = React.createRef();

  state = {
    showContent: false
  }

  hideContent() {
    if (this.state.showContent) {
      this.setState ({showContent: false})
    }
  }

  showContent() {
    if (!this.state.showContent) {
      this.setState ({showContent: true})
    }
  }

  render() {
    const style = { 
      background: this.props.background,
      backgroundColor: this.props.backgroundColor
    };
    let containerClassName = 'vertical-slide-container';
    if (this.props.animation) {
      containerClassName += ` animated ${this.props.animation}`;
    }

    if (this.state.showContent) {
      containerClassName += ' show'
    }
    return (
      <div className="vertical-slide-content" ref={this.content} style={ style }>
        <div className={containerClassName} ref={this.container}>
          <div className="content">{this.props.children}</div>
        </div>
      </div>
    )
  }
}