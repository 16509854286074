import anime from "animejs";
import React from "react";
import { Link, NavLink } from "react-router-dom";

import './Sidenav.scss';

import logo from '../../images/123-regenrinnenfrei.png';

export default class SideNav extends React.Component {
  backgroundPathRef = React.createRef();
  state = {
    isOpen: false
  }

  setAnimation() {

    // this.animation.
  }

  open() {
    this.setState({ isOpen: true });
    anime({
      targets: this.backgroundPathRef.current,
      d: [
        // 'M 10 0 T 10 24 Q 10 24 0 24 Q 5 12 0 0',
        'M 10 0 T 10 24 Q 10 24 0 24 Q 0 12 0 0'
      ],
      elasticity: 0,
      easing: 'linear',
      duration: 300,
      autoplay: true,
    });
  }

  close() {
    this.setState({ isOpen: false });
    anime({
      targets: this.backgroundPathRef.current,
      d: [
        'M 10 0 T 10 24 Q 10 24 0 24 Q 5 12 0 0',
        // 'M 10 0 T 10 24 Q 10 24 0 24 Q 0 12 0 0'
      ],
      elasticity: 0,
      easing: 'easeInOutQuad',
      duration: 300,
      autoplay: true,
    });
  }

  toggle() {
    if (this.state.isOpen) {
      this.close()
    } else {
      this.open();
    }
  }

  componentDidMount() {
    this.setAnimation();

    SideNav.sidenav = this;    
  }

  render() {
    return (
      <>
        <aside className={`sidenav ${this.state.isOpen ? 'open' : 'closed'}`}>
          <svg className="sidenav-background" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 24" width="10" height="24" preserveAspectRatio="none">
            <path ref={this.backgroundPathRef} d="M 10 0 T 10 24 Q 10 24 0 24 Q 5 12 0 0"/>
          </svg>
          <div className="sidenav-content " onClick={() => this.close()}>
            <nav>
              <Link to="/" style={{ margin: '0 auto', marginBottom: '1rem'}} >
                <img src={logo} alt="LOGO" style={{ height: '100px', width: 'auto', margin: '0 auto'}} />
              </Link>
              <ul className="list-style-none flex-1 nav">
                <li><NavLink to="/" activeclassname="active">Home</NavLink></li>
                <li><NavLink to="/unsere_leistungen" activeclassname="active">Unsere Leistungen</NavLink></li>
                <li><NavLink to="/reperatur_and_reinigung" activeclassname="active">Dachrinnenreperatur</NavLink></li>
                <li><NavLink to="/kontakt" activeclassname="active">Kontakt</NavLink></li>
                <li><NavLink to="/partner" activeclassname="active">Dienstleitungspartner</NavLink></li>
                <li><NavLink to="/referenzen" activeclassname="active">Referenzen</NavLink></li>
              </ul>
              <ul className="list-style-none flex-0 social">
                <li>
                  <a href="https://instagram.com/bluebirdfacility?utm_medium=copy_link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/bluebirdfacility/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
                  </a>
                </li>
              </ul>
              <ul className="list-style-none">
                <li className="footer-link one"><Link to="/impressum">Impressum</Link></li>
                {/* <li className="footer-link two"><Link to="/">Datenschutzerklärung</Link></li> */}
              </ul>
            </nav>
          </div>
        </aside>
        <div className="sidenav-backdrop" onClick={() => this.close()}></div>
      </>
    )
  }
}